<template>
  <div id="defaultId1" class="company_detail">
    <gui_card v-if="ImporttableData?.length > 0"
      id="ge6845"
      class="uc1096 pb20"
      label=""
      :isShowLabel="false"
    >
      <div>
        <h2 class="operations-head flex_center_between_box">
          <span class="head-titel">Lawsuits</span>
        </h2>
        <div class="table-biao">
          <el-table :data="ImporttableData" style="width: 100%" empty-text="No Data" class="table_height_30 table_font_size_14 table_contentHeight_40">
            <el-table-column prop="Category" label="Case Category" width="210" align="left" show-overflow-tooltip>
              <template slot-scope="scope">
                <span  class="table-content">{{ scope.row.category }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="Date" label="Date" width="185"  align="left" show-overflow-tooltip>
               <template slot-scope="scope">
                <span  class="table-content">{{ scope.row.date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="Title" label="Title"  align="left" show-overflow-tooltip> 
         
               <template slot-scope="scope">
                <span  class="table-content">{{ scope.row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="CaseNo" label="Case No."   align="left" show-overflow-tooltip> 
               <template slot-scope="scope">
                <span  class="table-content">{{ scope.row.caseNo }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="Count" label="Court"  align="left" show-overflow-tooltip> 
               <template slot-scope="scope">
                <span  class="table-content">{{ scope.row.court }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="CauseofAciton"  label="Cause of Action"   align="left" show-overflow-tooltip> 
               <template slot-scope="scope">
                <span  class="table-content">{{ scope.row.causeOfAction }}</span >
              </template>
            </el-table-column>
      
          </el-table>
           </div>
      </div>
    </gui_card>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import {

  getLawsuits
} from "@/api/companyApi";
import rectcard from "../../../../components/rectCard/index.vue";
import content_text from "../../../../components/text_content/index.vue";
import gui_card from "../../../../components/gui_card/gui_card.vue";
import tableVue from "../Performance/components/table.vue";
import ReLogin from "../../../../components/ReLogin.vue";

//importEnd

export default {
  name: "",

  props: {},

  components: {
    // gui_card start
    gui_card,
    // gui_card end

    rectcard,
    content_text,
    tableVue,
    ReLogin,
  },

  data() {
    return {
      reLoginVis: false,
 ImporttableData: [],
      newDialog: false,
      // srcviewscompanydetailsBackgroundSnapshotvueDataEnd
    };
  },

  computed: {},
  mounted() {
    this.getLawsuitsList()
  
  },
  methods:{
    getLawsuitsList(){
      getLawsuits({id3a:this.$route.query.id3a}).then(Res=>{
        if(Res.code==200){
      this.ImporttableData=Res.data.lawsuits
       let manManagementSummary=[
            {
              name: "Bankruptcy",
              id: "Bankruptcy ",
              num: Res.data.bankruptcyRecords,
            },
            {
              name: "Litigations",
              id: "Litigations",
               num: Res.data.litigationHearingRecords,
            },
       
     
            {
              name: "Dishonest Debtor",
              id: "Dishonest Debtor",
                    num: Res.data.dishonestDebtor,
            },
            {
              name: "Negative Records",
              id: "Negative Records",
                    num: Res.data.negativeRecords,
            },
               {
              name: "Lien",
              id: "Lien",
                    num: Res.data.lien,
            },
          ]
          localStorage.setItem('nineSquareGrid',JSON.stringify(Res.data.nineSquareGrid))
           this.$emit('UpdatamarkTotalList',Res.data.nineSquareGrid)
        }else{
          this.ImporttableData=[]
              this.$emit('UpdatamarkTotalList',[])
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      })
    }
  },
};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}
.importDetail-head {
  display: flex;
}
.head-titel{
  font-family: 'DMSans Bold';
  font-weight: 400;
    font-size: 2.142857142857143rem;
    display: flex;
    align-items: center;
}
.table-biao{
    height: auto;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 0.2857142857142857rem;
    margin-top: 1.4285714285714286rem;}
.operations-head {
  font-size: 2.142857142857143rem;
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}
.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}
/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-family: 'DMSans Bold';
  /* padding-left: 1.4285714285714286rem !important; */
  line-height: 2.142857142857143rem;
}
/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}
/deep/ .el-table th.el-table__cell {
    background-color: #f2f5f9 !important;
    font-family: 'DMSans Bold';
}
.uc1096 {
  height: 100%;
}

.table-content {
    font-size: 1.4285714285714286rem;
    color: #1A2332;
}
@media screen and (max-width: 1441px) {
  .table-content {
    font-size: 14px;
 
}
}
.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem;
}

</style>

